.PosButton {
    font-family: 'Segoe UI';
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 11px 40px;
    margin: 5px;
    border: none;
    background-color: #ffa40b;
    color: white;
    font-size: 16px;
    height: 46px;
    transition-property: background-color;
    transition-duration: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

    .PosButton:hover {
        background-color: #de9f36;
    }

    .PosButton:active {
        background-color: #c69039;
    }

    .PosButton.secondary {
        background-color: #aaa;
    }

        .PosButton.secondary:hover {
            background-color: #929292;
        }

        .PosButton.secondary:active {
            background-color: #888;
        }



    .PosButton:disabled {
        background-color: #ccc;
        cursor: initial;
    }

        .PosButton:disabled:hover {
            background-color: #ccc;
        }
