.POS-Table {
    background-color: white;
    border-collapse: collapse;
    font-family: "Segoe UI Light";
    width: 100%;
    table-layout: fixed;
}

    .POS-Table > tbody > tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .POS-Table > thead > tr > th {
        font-weight: bold;
        user-select: none;
        border-bottom: 1px grey solid;
    }
    .POS-Table > thead > tr > th > div {
        cursor: pointer;
    }


        .POS-Table > tbody > .selected {
            background-color: rgba(255, 164, 11, 0.47) !important;
        }

    .POS-Table > tbody > tr {
        height: 65px;
    }

    .POS-Table > tbody > tr.ordersPage {
        height: 115px;
    }

    .POS-Table > * > tr > * {
        /*border: 1px #797979 solid;*/
        margin: 0;
        padding: 6px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .POS-Table > tbody > tr:hover {
        background-color: rgba(255, 164, 11, 0.24);
    }

    .hasLink {
        cursor: pointer;
    }

.isDisabled {
    cursor: initial;
    background-color: #dfdfdf;
    color: #c5c0c0;
}

.hasLink:hover.isDisabled:hover {
    background-color: #dfdfdf;
}

.empty-row {
    background-color: white !important;
    text-align: center;
}

    .empty-row:hover {
        background-color: white !important;
    }

.POS-Table-Pagination {
    font-family: "Segoe UI Light";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    .POS-Table-Pagination > div > button {
        background-color: rgba(255, 164, 11);
        border: none;
        color: white;
        margin: 0 2px;
        width: 50px;
        height: 50px;
        border-radius: 2px;
    }

        .POS-Table-Pagination > div > button:disabled {
            background-color: rgb(180, 180, 180);
        }

    .POS-Table-Pagination > span > input {
        width: 35px;
        height: 22px;
        margin: 5px;
        font-family: inherit;
        font-size: 16px;
    }

.POS-Table-Pagination > div > .page-number {
    margin: 0 20px;
}

@media screen and (max-width: 960px) {
    .POS-Table {
        width: 890px;
    }
}
