.button__bar {
    position: absolute;
    left: 0;
    display: inline-block;
    vertical-align: middle;    
    margin: 30px;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0px;
    width: 240px;
    text-align: center;
}

.popup_button__bar {
    position: absolute;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: 60px;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0px;
    width: 180px;
    text-align: center;
}

.mobile_button__bar {
    position: absolute;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    margin-top: 5px;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0px;
    width: 120px;
    text-align: center;
}

.button__bar li,
.popup_button__bar li,
.mobile_button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.button__bar li button {
    border: none;
    background: rgba(51,51,51,0.2);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 40px;
    border-radius: 0px;
}

.popup_button__bar li button {
    border: none;
    background: rgba(51,51,51,0.2);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 30px;
    border-radius: 0px;
}

.mobile_button__bar li button {
    border: none;
    background: rgba(51,51,51,0.2);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 20px;
    border-radius: 0px;
}

.button__bar li.slick-active button,
.popup_button__bar li.slick-active button,
.mobile_button__bar li.slick-active button {
    background-color: #333;
}

.button__bar li button:hover,
.button__bar li button:focus,
.popup_button__bar li button:hover,
.popup_button__bar li button:focus,
.mobile_button__bar li button:hover,
.mobile_button__bar li button:focus {
    outline: 0;
}
