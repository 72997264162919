.Field-label-container {
    font-size: 18px;
    font-family: "Segoe UI Light";
    vertical-align: middle;
    text-align: right;
    width: 140px;
}

.Field-label-container.left-align {
    text-align: left;
}

.Field-label-container.textarea-label {
    vertical-align: initial;
    padding-top: 4px;
}

.Field-required-container.textarea-star {
    vertical-align: initial;
    padding-top: 4px;
}


.Field-required-container {
    color: #f59a23;
    width: 16px;
}

.Field-warning {
    font-size: 16px;
    color: #f59a23;
    margin: 0 0 8px 0;
    max-width: 350px;
}

.Field-input {
    border-radius: 3px;
    border: 1px grey solid;
    font-size: 18px;
    padding: 8px;
    margin: 0;
    font-family: "Segoe UI";
    resize: none;
    width: 100%;
    max-width: 350px;
    box-sizing: border-box;
}

textarea.Field-input {
    height: 200px;
}

.Field-input[type="checkbox"] {
    width: 40px;
    height: 40px;
    accent-color: #ffa40b;
    cursor: pointer;
}

.Field-input[type="checkbox"]:disabled {
    cursor: initial;
}

select.Field-input, .Field-input[type="date"] {
    cursor: pointer;
}

    select.Field-input:disabled, .Field-input[type="date"]:disabled {
        cursor: initial;
    }

.Field-static {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    max-width: 350px;
    height: 40px;
    display: flex;
    align-items: center;
}

.empty {
    color: #b9b9b9;
}